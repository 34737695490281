// Table Wrapper
div.table-wrapper {
  // Topbar
  div.toolbar {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    div.right {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      @media screen and (min-width: 1024px) {
        flex-direction: row;
        margin-left: auto;
      }
    }

    div.dropdown {
      .dropdown-toggle {
        @media screen and (max-width: calc(1024px - 0.2px)) {
          width: 100%;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      margin-bottom: none;
    }
  }

  // Filters' card
  .card {
    flex-direction: column !important;

    &-header {
      display: flex;
      flex-direction: row;
      padding: 1rem;
    }

    &-body {
      padding: 0 1rem;

      div.input-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media (min-width: calc(768px)) {
          flex-direction: row;
          flex-wrap: wrap;
          gap: 0;

          & > div {
            padding: 1rem;
          }
        }

        @media (min-width: calc(768px)) and (max-width: calc(1500px - 0.2px)) {
          & > div {
            flex-basis: 50%;
          }
        }

        @media (min-width: 1500px) {
          & > div {
            flex-basis: 25%;
          }
        }
      }

      @media (min-width: calc(768px)) {
        padding: 0;
      }
    }

    &-footer {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;

      @media (min-width: calc(768px)) {
        flex-direction: row;
        align-items: center;
        // justify-content: flex-end;
      }
    }
  }

  // Table
  table.table {
    color: $dy-blue;

    &-bordered > :not(caption) > * > * {
      border-width: 0px;
    }

    * .checkbox,
    * .index {
      max-width: 40px;
      text-align: center;
    }

    *.checkbox {
      width: 40px;
    }

    *.index {
      max-width: 3rem;
    }

    *.id {
      max-width: 70px;
      text-align: center;
    }

    .form-check-input[type="checkbox"]:checked,
    .form-check-input[type="checkbox"]:indeterminate {
      background-color: $dy-green;
      color: $dy-grey-light;
      border: 1px solid $dy-green;
    }

    > :not(:first-child),
    thead,
    tr,
    td,
    th {
      border-top: none;
    }

    > thead {
      th {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        color: $dy-green;
      }

      th.sortable {
        &:hover {
          background-color: inherit;
        }
      }
    }

    > tbody {
      td.loading {
        height: 350px;
      }
      tr {
        font-size: 14px;
        font-weight: 600;
      }
      tr.selected > * {
        background: $dy-grey-light;
      }
    }

    tr,
    td {
      color: $dy-blue;

      a.crud {
        font-weight: 700;
        color: $dy-green;
        text-decoration: underline;
      }

      .actions {
        div.dropdown-menu {
          a.dropdown-item.btn-link {
            color: $dy-green;
          }

          button.dropdown-item.delete {
            color: $dy-red;
          }

          &:not(*.dropdown-item.delete) {
            color: $dy-blue;
          }
        }
      }
    }

    td {
      // vertical-align: top;
      .checkbox {
        padding: 0;
      }

      span {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        line-clamp: 4;
        overflow: hidden;
        min-width: 200px;

        &:hover {
          overflow-y: auto;
          // line-clamp: unset;
          // -webkit-line-clamp: unset;
        }
      }

      white-space: break-spaces;
    }

    .form-check-input[type="checkbox"] {
      border: 1px solid $dy-green;
      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        border: 1px solid $dy-green;
      }
    }
  }

  // Pagination controls
  div.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    div:first-child {
      order: 0;
    }

    div:nth-child(2) {
      order: 1;
    }

    div:last-child {
      order: 2;
    }

    @media screen and (max-width: map-get($grid-breakpoints, md)) {
      flex-direction: column;
      align-items: normal;

      div:first-child {
        order: 1;
      }

      div:nth-child(2) {
        order: 0;
      }

      div:last-child {
        order: 2;
      }
    }

    .page-item .page-link,
    .size {
      font-size: 14px;
      color: $dy-grey-text;
      border: solid 1px $gray-900 !important;
    }

    .pagination > li > a:focus,
    .pagination > li > span:focus {
      outline: none;
      box-shadow: none;
      background-color: $dy-white !important;
    }

    .pagination > li > a:hover,
    .pagination > li > span:hover {
      color: $dy-white;
      background-color: $dy-grey-text;
      border: solid 1px $gray-900;
    }

    .pagination > li.user-select-none > a.page-link,
    .pagination > li.user-select-none > a.page-link:hover {
      background-color: $dy-white;
      color: $dy-grey-text;
      cursor: default;
    }

    .size {
      border: solid 1px $gray-900 !important;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  div.actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    max-width: 32px;
  }
}

// Resource view
div.resource {
  gap: 1rem;

  div.actionsbar {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (max-width: calc(768px - 0.2px)) {
      & > * {
        flex: 1;
      }
    }

    @media (min-width: 576px) {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
    }

    .search-bar {
      min-width: 300px;

      div.input-group {
        min-height: 39px;
      }

      .form-control,
      .btn {
        border: 1px solid $dy-grey-bg;
        background-color: $dy-grey-primary;
      }

      .form-control {
        border-right: none !important;
      }

      .btn {
        border-left: none;
      }

      div.input-group:has(.form-control:focus) {
        .btn {
          border: 1px solid $gray-500;
        }
      }
    }
  }

  div.actionsbar:has(div.search-bar) {
    flex-wrap: wrap;

    @media screen and (min-width: 576px) and (max-width: calc(768px - 0.2px)) {
      .search-bar {
        flex-basis: 100%;
      }
    }
  }

  & > .content > div.resource-field {
    color: $dy-grey-text;
    padding: 0.5rem;
    border-bottom: 1px solid $dy-grey-bg;

    &:nth-child(odd) {
      background-color: $dy-grey-light;
    }

    .ico-align {
      text-align: right;
      padding-right: 15px;
    }
  }
}

div.resource-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1.25rem;
}

// Form
form {
  // margin-bottom: 65px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }

  .dyn-form-fields,
  .nested-form {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .dyn-form-btn,
    .dyn-form-field {
      flex-grow: 1;
      width: 48%;

      @media screen and (max-width: map-get($grid-breakpoints, md)) {
        width: 100%;
      }
    }

    .dyn-form-field.w-full {
      width: 100%;
    }
  }

  .dyn-form-controls {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;

    &.padding {
      padding: 2rem 0;
    }

    & > button {
      height: 40px;
    }

    @media screen and (min-width: 768px) {
      flex-direction: row;

      &.padding {
        padding: 3.5rem 0;
      }

      & > * {
        flex-basis: 33.33%;
      }
    }
  }

  div.dyn-form-fields.single,
  div.nested-form.single {
    flex-direction: column;
  }

  .nested-dyn-form-field {
    width: 100%;
  }

  div.form-section {
    background-color: $dy-grey-primary;
    border-radius: 6px;
    padding: 1rem;

    &:focus-visible,
    &:focus {
      outline: none;
    }

    &-header {
      margin-bottom: 1rem;
      border-bottom: 1px solid $dy-grey-bg;
    }

    span.input-group-text {
      justify-content: center;
      background-color: #fff;
      border-color: #ced4da;
      font-size: 14px;
      font-weight: 500;
      color: $dy-grey-text;
    }

    span.input-group-text:has(+ .form-control.is-invalid),
    .form-control.is-invalid + span.input-group-text {
      border-color: var(--bs-form-invalid-border-color);
    }

    span.input-group-text:has(+ .form-control:focus),
    .form-control:focus + span.input-group-text {
      border-color: $gray-500;
    }

    div.input-group:has(.form-control:disabled),
    div.input-group:has(.form-control:read-only:not(input[type="file"])) {
      .input-group-text {
        background-color: var(--bs-secondary-bg);
        opacity: 1;
      }
    }
  }
}

#nested-form.has-error {
  > span.text-danger {
    display: none;
  }
}

// Input styles
.form {
  &-label {
    font-size: 15px;
    font-weight: 600;
    color: $dy-grey-text;
  }

  &-control,
  &-select {
    color: $dy-grey-text;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    min-height: 38px;
  }

  &-input:focus,
  &-control:focus,
  &-select:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid $gray-500 !important;
  }

  &-check-input[type="checkbox"]:checked,
  &-check-input[type="checkbox"]:indeterminate {
    background-color: $dy-blue;
    color: $dy-grey-light;
    border: 1px solid $dy-blue-hover;
  }

  &-check-input[type="radio"]:checked {
    background-color: #fff;
    border-color: $dy-grey-bg;
    --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%272%27 fill=%27%23003280%27/%3e%3c/svg%3e");
  }

  &-check-input[type="checkbox"]:focus,
  &-check-input[type="radio"]:focus {
    border: 1px solid $dy-blue-active;
  }

  &-check:focus-visible,
  &-check-input[type="checkbox"]:focus-visible,
  &-check-input[type="radio"]:focus-visible {
    outline: none;
  }

  &-control:read-only:not(input[type="file"]) {
    background-color: var(--bs-secondary-bg);
    opacity: 1;
  }

  &-control::file-selector-button {
    display: none;
  }
}

div.text-area-container {
  &.form-control {
    height: auto !important;
  }

  textarea {
    background-color: inherit;
    border: none;
    resize: none;
    outline: none;
    max-width: 100%;
    max-height: 100%;
  }

  button {
    right: 40px;
    padding: 0.5rem;
    position: absolute;
  }
}

// Mediaquery

@media screen and (max-width: map-get($grid-breakpoints, md)) {
  .form-fields {
    flex-direction: column;
  }
}

.dyn-form-switch {
  --min-width: 100px;
  position: relative;
  display: inline-block;
  min-width: var(--min-width);
  height: 38px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;

    -webkit-transition: 0.4s;
    transition: 0.4s;

    &.round {
      border-radius: 32px;
      border: 1px solid #ced4da;

      &:before {
        border-radius: 50%;
      }
    }

    &.is-invalid {
      border-color: var(--bs-danger) !important;
    }
  }

  .slider::before {
    position: absolute;
    content: "";
    height: 32px;
    width: 32px;
    top: 2px;
    left: 2px;
    bottom: 2px;
    background-color: $dy-blue;

    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider::before {
    -webkit-transform: translateX(calc(var(--min-width) - 38px));
    -ms-transform: translateX(calc(var(--min-width) - 38px));
    transform: translateX(calc(var(--min-width) - 38px));

    background-color: $dy-green;
  }

  .on,
  .off {
    position: absolute;
    top: 50%;
  }

  input:checked + .slider .on {
    display: block;
    color: $dy-grey-text;
    transform: translate(16px, -50%);
  }

  input:checked + .slider .off {
    display: none;
    color: transparent;
  }

  .on {
    display: none;
    color: transparent;
  }

  .off {
    left: 0;
    transform: translate(38px, -50%);
    color: $dy-grey-text;
  }
}

#isSeedCoated.dyn-form-switch {
  --min-width: 75px;
}

div.view-section {
  background-color: $dy-grey-primary;
  border-radius: 6px;
  padding: 1rem;

  &:focus-visible,
  &:focus {
    outline: none;
  }
}

div.delete-field-and-culture-container {
  padding: 0;
  align-self: stretch;

  display: flex;
  align-items: center;

  button {
    padding: 0;
    min-width: 38px !important;
    height: 38px;
    margin-left: auto;
  }

  // @media screen and (min-width: map-get($grid-breakpoints, md)) {
  //   padding-top: 1.8rem;
  //   align-self: start;
  // }
}

.form-control:not(:focus).is-invalid {
  z-index: 0 !important;
}

div.fertilizer-form-modal {
  @media (min-width: map-get($grid-breakpoints, md)) {
    .modal-dialog {
      min-width: calc(map-get($grid-breakpoints, md) - 50px);
      max-width: map-get($grid-breakpoints, md);
      margin-right: auto;
      margin-left: auto;
    }
  }
}

.no-wrap {
  white-space: nowrap;
}
