@import "~@csstools/normalize.css";
@import "~react-perfect-scrollbar/dist/css/styles.css";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/_transitions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~react-bootstrap-typeahead/css/Typeahead.scss";
@import "~react-bootstrap-typeahead/css/Typeahead.bs5.scss";
@import "~react-toastify/scss/main.scss";
@import "~bootstrap-icons/font/bootstrap-icons.scss";

// Main color
$dy-white: #fff;

// Blue palette
$dy-blue: #003280;
$dy-blue-hover: #0b2866;
$dy-blue-disabled: #384c79;
//$dy-blue-hover: #001940;
$dy-blue-active: #536a9d;

// Green palette
$dy-green: #009076;
$dy-green-hover: #017460;
$dy-green-disabled: #508d83;

// Grey palette
$dy-grey-light: #f2f2f2;
$dy-grey-primary: #f5f5f5;
$dy-grey-secondary: #ededed;
$dy-grey-bg: #dfdfdf;
$dy-grey-text: #636363;
$dy-grey-hover: #c4c4c4;

// Red
$dy-red: #c1393e;

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --bs-body-font-size: 14px;
  --toastify-color-info: #{$dy-blue};
  --toastify-color-success: #{$dy-green};
  --toastify-color-warning: #cfa90f;
  --toastify-color-error: #{$dy-red};
}

*,
body {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  min-height: 100dvh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  color: $dy-blue;
  font-weight: 600;
  margin: 0;
  padding: 0;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.45rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1.1rem;
}

h6 {
  font-size: 1rem;
}

p {
  margin: 0;
  padding: 0;
}

b {
  color: $dy-green;
  text-transform: uppercase;
}

.fs-5 {
  font-weight: 500;
  font-size: 15px !important;
}

.fs-6 {
  font-weight: 500;
  font-size: 14px !important;
}

.text-primary {
  color: $dy-blue !important;
}

.text-success {
  color: $dy-green !important;
}

.text-secondary {
  color: $dy-grey-text !important;
}

.text-danger {
  color: $dy-red !important;
}

.btn {
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
}

.btn-link {
  color: $dy-green;

  &:active {
    color: $dy-green !important;
  }

  &:focus-visible {
    color: $dy-green;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    color: $dy-green-hover;
  }
}

.btn-primary {
  background-color: $dy-blue !important;
  border-color: transparent;

  &:focus-visible {
    background: transparent;
    box-shadow: none !important;
    border: none;
  }

  @media (hover) {
    &:hover,
    &:active,
    .show {
      background-color: $dy-blue-hover !important;
      border-color: transparent !important;
      box-shadow: none;
    }
  }
}

.btn-success {
  background-color: $dy-green !important;
  border-color: transparent;

  &:focus-visible {
    background: transparent;
    box-shadow: none !important;
  }

  @media (hover) {
    &:hover,
    &:active,
    .show {
      background-color: $dy-green-hover !important;
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }
}

.btn-outline-success {
  --bs-btn-color: #{$dy-green};
  --bs-btn-border-color: #{$dy-green};
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{$dy-green};
  --bs-btn-hover-border-color: #{$dy-green};
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{$dy-green};
  --bs-btn-active-border-color: #{$dy-green};
  --bs-btn-disabled-color: #198754;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #198754;
  --bs-gradient: none;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-shadow: none;

  &.show {
    border: 1px solid $dy-green !important;
  }
}

.btn-outline-secondary {
  color: $dy-grey-text !important;
  background-color: transparent !important;
  border: 1px solid $gray-500 !important;

  &.show {
    border: 1px solid $gray-500 !important;
  }

  @media (hover) {
    &:hover,
    &:active,
    &:focus-visible,
    .show {
      color: $dy-grey-text !important;
      background-color: transparent !important;
      border: 1px solid $gray-500 !important;
      box-shadow: none !important;
    }
  }
}

.btn-outline-danger {
  color: $dy-red !important;
  background-color: transparent !important;
  border-color: $dy-red !important;

  &.show {
    border: 1px solid $dy-red !important;
  }

  @media (hover) {
    &:hover,
    &:active,
    &:focus-visible,
    .show {
      color: $dy-white !important;
      background-color: $dy-red !important;
      border-color: transparent;
      box-shadow: none !important;
    }
  }
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: none;
  border: 1px solid #dee2e6;
  box-shadow: none;
}

div.dropstart {
  .dropdown-toggle {
    color: $dy-grey-text;

    &:active {
      color: $dy-grey-text !important;
    }

    &:focus-visible {
      box-shadow: none;
      border: none;
    }

    &:before {
      display: none !important;
    }
  }
}

div.dropend {
  .dropdown-toggle {
    &:focus-visible {
      box-shadow: none;
      border: none;
    }

    &:after {
      display: none !important;
    }
  }
}

div.dropdown-menu {
  border-radius: 6px;

  *.dropdown-item {
    font-size: 14px;
    color: $dy-grey-text;

    &:active {
      background-color: $dy-grey-secondary !important;
    }

    &:focus-visible {
      box-shadow: none;
    }

    input[type="checkbox"]:checked,
    input[type="radio"]:checked {
      background-color: $dy-grey-text;
      color: $dy-grey-light;
      border: 1px solid $gray-800;
    }

    input[type="radio"]:checked {
      --bs-form-check-bg-image: none;
    }
  }
}

div.card,
div.horizontal-card {
  background-color: $dy-grey-secondary;
  border: none;
  border-radius: 6px;
}

div.card {
  &-title {
    margin: 0;
  }

  &-header,
  &-footer {
    background-color: transparent;
    border: none;
  }

  &-body {
    font-size: 14px;
    font-weight: 500;
  }
}

div.horizontal-card {
  flex-direction: column;

  div.card-header {
    background-color: transparent;
    border: none;
  }

  &-left {
    width: 100%;

    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      width: 40%;
    }
  }

  &-right {
    width: 100%;

    @media screen and (min-width: map-get($grid-breakpoints, lg)) {
      width: 60%;
    }
  }

  div.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .card {
      &-header {
        padding: 1rem 1rem 0.5rem 1rem;
      }

      &-title {
        color: $dy-blue;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        width: 100%;
        max-width: 40rem;
        text-wrap: pretty;
      }

      &-body {
        display: flex;
        flex-direction: column;
        height: 100%;
        color: $dy-blue;
        padding: 0.5rem;

        .text-content {
          display: flex;
          flex-direction: column;
          padding: 0.5rem;
          border-top: 1px solid $gray-500 !important;

          label {
            color: $dy-green;
            font-weight: 700;
            margin-bottom: 0.25rem;
          }
        }
      }

      &-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 65px;
        border-top: unset;

        @media screen and (max-width: map-get($grid-breakpoints, lg)) {
          justify-content: center;
        }
      }
    }
  }

  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    flex-direction: row;
  }
}

.notes.modal-body {
  display: flex;
  height: 50vh;
  max-height: 50vh;

  div.quill-container {
    width: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;

    .quill {
      height: 90% !important;
    }
  }
}

select.language-selector {
  &:focus-visible {
    outline: none;
  }
}

.w-65 {
  width: 65%;
}

.w-90 {
  width: 90%;
}

// Dynamica defined styles
@import "./icons.scss";
@import "./layout.scss";
@import "./widgets.scss";
@import "./crud.scss";
@import "./device.scss";
@import "./dashboard.scss";
@import "./fields.scss";
@import "./profile.scss";
@import "./notifications.scss";
@import "~react-datepicker/src/stylesheets/datepicker.scss";

.dashboard-dropdown {
  border: none;
}

.dashboard-dropdown::after,
.dashboard-dropdown::before {
  display: none;
}

div.toast-alert {
  @media screen and (min-width: map-get($grid-breakpoints, md)) {
    width: 35%;
  }
}

div.titlebar {
  display: flex;
  flex-direction: row;
  align-items: baseline;

  a {
    padding: 0;
    max-height: 34px;

    i {
      font-size: 1.3rem;
    }
  }

  .title {
    text-align: right;
    word-break: break-word;
    text-wrap: pretty;
    margin-left: auto;
    width: 85%;
    font-size: 1.5rem;

    &:only-child {
      text-align: left;
      margin-left: 0;
      max-width: 100%;
    }
  }

  @media screen and (min-width: 1024px) {
    align-items: center;

    .title {
      margin-left: 0;
      width: auto;
      text-align: left;
    }

    a {
      margin-top: 0;
      margin-right: 0.5rem;
    }
  }
}

.form-control,
.form-select {
  border-color: #ced4da;
}

.form-control::placeholder,
.form-select::placeholder,
.css-1jqq78o-placeholder {
  opacity: 0.5;
}

.form-control:focus,
.form-select:focus {
  color: $dy-grey-text;
}

// Mobile portrait lock: https://css-tricks.com/snippets/css/orientation-lock/
@media screen and (min-width: 320px) and (max-width: 667px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100dvh;
    overflow: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

.tooltip {
  --bs-tooltip-max-width: 400px;
  --bs-tooltip-bg: #fff;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-color: $dy-grey-text;

  .tooltip-inner {
    padding: 25px;
    text-align: justify;
    hyphens: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
    border: 0.5px solid #e6e6e6;

    p > a {
      color: $dy-green;
    }
  }

  @media screen and (min-width: 300px) and (max-width: 768px) {
    --bs-tooltip-max-width: 300px;
  }
}

.break-space {
  white-space: break-spaces;
}

.bg-primary {
  background-color: $dy-blue !important;
}

.bg-danger {
  background-color: $dy-red !important;
}

.btn-danger {
  $bgHover: darken($dy-red, 10%);
  $bgDisabled: lighten($dy-red, 20%);
  --bs-btn-bg: #{$dy-red};
  --bs-btn-border-color: #{$dy-red};
  --bs-btn-hover-bg: #{$bgHover};
  --bs-btn-hover-border-color: #{$bgHover};
  --bs-btn-active-bg: #{$bgHover};
  --bs-btn-active-border-color: #{$bgHover};
  --bs-btn-disabled-bg: #{$bgDisabled};
  --bs-btn-disabled-border-color: #{$bgDisabled};
}

.stroke-primary {
  stroke: $dy-blue;
}

.stroke-success {
  stroke: $dy-green;
}

.stroke-white {
  stroke: #fff;
}

.industrial-label-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(230, 230, 230, 0.5);
}

.label-external-border {
  border: 1px solid var(--bs-border-color);
}

.industrial-label-wrapper {
  width: 5cm; // Should match LABEL_WIDTH_CM
  height: 9.7cm; // Should match LABEL_HEIGHT_CM
  padding: 0rem;

  .industrial-label {
    font-size: 10px;
    font-family: "Roboto";

    border: 1px solid black;
    border-radius: 6px;
    padding: 1rem 0.7rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    background-color: #fff;

    .logo {
      text-align: center;
    }

    .logo img {
      width: auto; // Prevent scaling beyond intrinsic width
      height: 35px; // Set fixed height based on source image resolution
      max-width: 100%; // Ensure it doesn't exceed parent
    }

    .device {
      margin-bottom: 0.25rem;
      font-weight: bold;
      text-align: center;
      font-size: 11px;
    }

    .device-info {
      padding-left: 0.2rem;
    }

    .device-info div {
      margin-bottom: 0.25rem;
    }

    .qr-code {
      text-align: center;
    }

    .certifications {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0.5rem;

      img {
        height: 40px; // Fixed height
        width: auto; // Prevent scaling beyond intrinsic width
        max-width: 100%; // Ensure it doesn't exceed parent
      }
    }
  }
}

.react-datepicker__input-container.react-datepicker__view-calendar-icon {
  display: flex;
  flex-direction: row-reverse;

  i {
    padding: 0.55rem 1rem;
  }

  input {
    padding: 0.375rem 0.75rem;
  }
}

.modal-tooltip {
  &.modal {
    --bs-modal-width: 800px;

    .modal-header {
      border-bottom: none;

      .modal-title {
        margin-left: auto;
      }
    }
  }
}

#notes-section {
  .ql-editor {
    min-height: 230px;
  }
}

.border-secondary {
  border-color: #ced4da !important;
}

.activity-view-mobile {
  height: calc(100dvh - 65px);

  .activity {
    height: calc(100dvh - 210px);
    overflow: hidden;
    overflow-y: auto;
  }
}

.bg-grey-primary {
  background-color: $dy-grey-primary;
}

.btn-outline-primary {
  --bs-btn-color: #{$dy-blue};
  --bs-btn-border-color: #{$dy-blue};
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #{$dy-blue};
  --bs-btn-hover-border-color: #{$dy-blue};
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #{$dy-blue-active};
  --bs-btn-active-border-color: #{$dy-blue};
  --bs-btn-disabled-color: #{$dy-blue-disabled};
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #{$dy-blue-disabled};
  --bs-gradient: none;
  --bs-btn-focus-shadow-rgb: none;
  --bs-btn-active-shadow: none;

  &.show {
    border: 1px solid $dy-blue !important;
  }
}
